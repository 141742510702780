@tailwind base;
@tailwind components;
@tailwind utilities;

@import "css/satoshi.css";

body {
  margin: 0;
  height: 100%;
  font-family: 'Satoshi-Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFE3;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-moz-selection { /* Code for Firefox */
  color: black;
  background: #FFFFE3;
}

::selection {
  color: black;
  background: #FFFFE3;
}

::-webkit-scrollbar {
  scroll-behavior: smooth;
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: #ffffe324;
}
::-webkit-scrollbar-thumb {
  background-color: #ffffe3;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #ffffe367;
}